import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router";
import http from "./plugins/http";
import http2 from "./plugins/http2";
import mediaHttp from "./plugins/mediaHttp";
import publicHttp from "./plugins/publicHttp";
import rules from "@/api/rules";
import { createYmapsVue2 } from 'vue-yandex-maps';
import VueMarqueeSlider from 'vue-marquee-slider';

import "./plugins/vuetify";
import "./theme/default.sass";
import "@mdi/font/css/materialdesignicons.css";
import "./components/components"
import "@/scss/style.scss";

Vue.use(createYmapsVue2({
  apikey: '5198c492-5bbc-4b98-9f60-61aa70cbd2a2',
}));

import HeroIcon from 'vue-heroicons'
import {
    dashboard,
    reports,
    video,
    establishments,
    news,
    banners,
    contacts,
    settings,
    notification,
    users,
    pencil,
    trash,
    roles,
    eye,
    picture,
    acc,
    logout,
    kitchen,
    phone,
    address,
    check,
    time,
    lang,
    cities,
    archive,
    widgets,
    menu,
    camera,
    photographer,
    mobilephone,
    poster,
    kg,
    book,
    pdf,
    arrowUp,
    arrowRight,
    smallArrowRight,
    smallArrowDown,
    filter,
    heart,
    share,
    hours,
    global,
    marker,
    money,
    smartphone,
    menuDown,
    menuUp,
    map,
    robot,
    redirect,
} from './icons/icons'

HeroIcon.add([
    dashboard,
    reports,
    video,
    establishments,
    news,
    banners,
    contacts,
    settings,
    notification,
    users,
    pencil,
    trash,
    roles,
    eye,
    picture,
    acc,
    logout,
    kitchen,
    phone,
    address,
    check,
    time,
    lang,
    cities,
    archive,
    widgets,
    menu,
    camera,
    photographer,
    mobilephone,
    poster,
    kg,
    book,
    pdf,
    arrowUp,
    arrowRight,
    smallArrowRight,
    smallArrowDown,
    filter,
    heart,
    share,
    hours,
    global,
    marker,
    money,
    smartphone,
    menuDown,
    menuUp,
    map,
    robot,
    redirect,
])
Vue.use(HeroIcon)


Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$http2 = http2;
Vue.prototype.$mediaHttp = mediaHttp;
Vue.prototype.$publicHttp = publicHttp;
Vue.prototype.$rule = rules;


const token = localStorage.getItem("token");
if (token) {
    http2.defaults.headers.common["Authorization"] = "Bearer " + token;
    http.defaults.headers.common["Authorization"] = "Bearer " + token;
    mediaHttp.defaults.headers.common["Authorization"] = "Bearer " + token;
}


Vue.config.productionTip = false;


window.dbg = (data) => {
    console.debug(data)
    return data;
}


window.tbl = (data) => {
    console.table(JSON.parse(JSON.stringify(data)))
    return data;
}
Vue.use(VueMarqueeSlider)


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
